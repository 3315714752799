<script>
import moment from 'moment';
import EventManage from '@/views/event/EventManage'
import utils from '@/services/utils'

import { EVENT_TABLE_OPTIOINS, SRDS_EVENT_TABLE_COLUMS as EVENT_TABLE_COLUMS} from "@/constants/eventSettings"
import { LEVEL_TYPES, SRDS_SENSOR_TYPES as SENSOR_TYPES, CLOSE_TYPES } from "@/constants/eventSettings"

export default {
  name: 'SRDSEventManage',
  extends: EventManage,
  mounted() {
    this.columns = _.cloneDeep(EVENT_TABLE_COLUMS),
    this.options.search = true;
    this.levelTypes = LEVEL_TYPES[8]

    this.setFormatter('created_at', utils.dateFormatter);
    this.setFormatter('is_closed', this.closeFormatter);
    this.setFormatter('site', this.siteFormatter);

    _.find(this.columns, { field: 'device_name' }).formatter = this.nameFormatter;
    _.find(this.columns, { field: 'device_address' }).visible = true;
    _.find(this.columns, { field: 'pre_angle_change' }).formatter = this.pre_angleStatusFormatter;
    _.find(this.columns, { field: 'angle_change' }).formatter = this.sensorValFormatter;
    _.find(this.columns, { field: 'sensor_scope' }).visible = false;
  },
  methods: {
    sensorScopeFormatter(value, row, index){ 
      var result = " ";
      result += `<i class="material-icons" style="font-size:30px;">zoom_in</i>`;
      return result 
    },
    sensorValFormatter(value, row, index){
      var result = "";
      var thresholds = utils.getThresholds();

      var level0= _.find(thresholds, {sensor_type : 8, level : 0 }).min; //0
      var level2= _.find(thresholds, {sensor_type : 8, level : 2 }).min; //3
      var level3= _.find(thresholds, {sensor_type : 8, level : 3 }).min; //5

      var current_value = Object.entries(row.sensor_value).map(ele => { 
          var value = ele[1]
          return value; 
        })

      var diff = Object.entries(row.angle_diff).map(ele => { 
          var value = ele[1]
          return value; 
        })
      var span = " "
      var xyzString = ["x :  ", " y :  ", " z :  "];

      for(var i = 0 ; i < current_value.length; i++){
          
          if(diff[i] > level3 || diff[i] < (level3*(-1))){ 
          span = "<span style = 'color: red; font-weight:bolder'>"
            
          }else if (diff[i] > level2 || diff[i] < (level2*(-1))){ 
            span = "<span style = 'color: orange;'>"
          }
          else span = "<span style = 'color: green; '>"
          result += ' • '+ xyzString[i] + " " + current_value[i] +  "º  ( " + span + diff[i] + "</span> ) "
        }
      
      return result;
    },
    pre_angleStatusFormatter(value, row, index) {
      var result = "";      

      if (_.isUndefined(value)){
          console.log("isUndefined");
          return;
      }
      //console.log(result);
        result += 'x: ' + value.x + '(º) ';
        result += 'y: ' + value.y + '(º) ';
        result += 'z: ' + value.z + '(º)';

      return result;
    },
    onChange(event) {
      var index = event.target.value
      this.levelTypes = LEVEL_TYPES[index]
    },
    queryEvent() {
      var self = this;
      var data = {
        from: moment(this.dates[0]).format('YYYY-MM-DD'),
        to: moment(this.dates[1]).format('YYYY-MM-DD')
      }
      if (this.sensor !== -1) data['sensor_type'] = this.sensor;
      if (this.level !== -1) data['level'] = this.level;
      if (this.close !== -1) data['is_closed'] = this.close;
      this.$store.dispatch('rest/method', {model:'events', method:'getAlarmList', data:data})
        .then((res) => {
          self.data = [];
          res.forEach(function(row, index) {
            if(row.service_type === 'srds'){
              self.data.push({
                created_at: row.created_at,
                description: row.description,
                service_type: row.service_type,
                sensor_type: row.sensor_type,
                sensor_value: row.sensor_value,
                pre_angle_change: row.standard,
                angle_diff: row.angle_diff,
                sensor_id: row.sensor_id,
                is_closed: row.is_closed,
                level: row.level,
                device_guid: row.device_guid,
                device_name: _.get(row, "device.name"),
                device_address: _.get(row, "device.address"),
                site: _.get(row, "device.site_guid"),
                guid: row.guid,
              });
            }
          })
          self.enableExcel = self.data.length > 0 ? true : false;
        })
        .catch((err) => {
          console.log('queryEvent event: ', err.toString())
          this.$refs.notifyDialog.show(err.toString())
        })
    },
  }
}
</script>